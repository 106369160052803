/** will be imported in preview.html */

const changeAllBoxEdit = () => {
  document.querySelectorAll('.is-box-edit').forEach((element) => {
    if (element.classList.contains('box-settings-attached')) {
      return;
    }
    element.innerHTML = /* html */ `
          <lyst-fa-icon 
            type="solid" 
            icon="gear" 
            custom-color="#FFF">
          </lyst-fa-icon>
        `;
    element.classList.add('box-settings-attached')
  });
};


window.changeAllBoxEdit = changeAllBoxEdit;

const changeBoxToolButtons = () => {
  

  const interval = setInterval(() => {
    const targetNode = document.body;
    if (!targetNode || !document.querySelectorAll('.is-box-edit').length) {
      return;
    }
    clearInterval(interval);

    changeAllBoxEdit();

    /* ----- attach mutation observer start ----- */
    // Callback function to execute when mutations are observed
    // const callback = () => {
    //   changeAllBoxEdit();
    // };

    // // Create an observer instance linked to the callback function
    // const observer = new MutationObserver(callback);

    // // Start observing the target node for configured mutations
    // observer.observe(targetNode, { subtree: true, childList: true });

    /* ----- attach mutation observer end ----- */
  }, 1000);

};

export default changeBoxToolButtons;
