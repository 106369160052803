/** will be imported in preview.html */
const clickColumnSettingsInParent = () => {
  (
    parent.document.querySelector(
      '.elmmore.active .elm-settings'
    ) as HTMLButtonElement
  )?.click();
};

const changeAllElmMore = () => {
  document.querySelectorAll('.elm-more').forEach((element) => {
    if (element.classList.contains('elm-settings-attached')) {
      return;
    }
    element.innerHTML = /* html */ `
          <lyst-fa-icon size="c2" type="solid" icon="gear" custom-color="#FFF"></lyst-fa-icon>
        `;
    element.addEventListener('click', clickColumnSettingsInParent);
    element.classList.add('elm-settings-attached');
  });
};

const changeAllElmAdd = () => {
  document.querySelectorAll('.elm-add').forEach((element) => {
    if (element.classList.contains('elm-add-changed')) {
      return;
    }
    element.innerHTML = /* html */ `
          <lyst-fa-icon size="c2" type="solid" icon="plus" custom-color="#FFF"></lyst-fa-icon>
        `;
    element.classList.add('elm-add-changed');
  });
};

const changeAllElmRemove = () => {
  document.querySelectorAll('.elm-remove').forEach((element) => {
    if (element.classList.contains('elm-remove-changed')) {
      return;
    }
    element.innerHTML = /* html */ `
          <lyst-fa-icon size="c2" type="solid" icon="trash" custom-color="#FFF"></lyst-fa-icon>
        `;
    element.classList.add('elm-remove-changed');
  });
};


window.changeElmOptions = () => {
  changeAllElmMore();
  changeAllElmAdd();
  changeAllElmRemove();
};

const changeElmToolButtons = () => {
  

  const interval = setInterval(() => {
    const targetNode = document.body;
    if (!targetNode || !document.querySelectorAll('.elm-more').length) {
      return;
    }
    clearInterval(interval);

    changeAllElmMore();
    changeAllElmAdd();
    changeAllElmRemove();

    /* ----- attach mutation observer start ----- */
    // Callback function to execute when mutations are observed
    // const callback = () => {
    //   changeAllElmMore();
    //   changeAllElmAdd();
    //   changeAllElmRemove();
    // };

    // // Create an observer instance linked to the callback function
    // const observer = new MutationObserver(callback);

    // // Start observing the target node for configured mutations
    // observer.observe(targetNode, { subtree: true, childList: true });

    /* ----- attach mutation observer end ----- */
  }, 1000);
};

export default changeElmToolButtons;
