/** will be imported in preview.html */
const clickColumnSettingsInParent = () => {
  (
    parent.document.querySelector(
      '.cell-settings[type="button"]'
    ) as HTMLButtonElement
  )?.click();
};

const changeAllCellMore = () => {
  document.querySelectorAll('.cell-more').forEach((element) => {
    if (element.classList.contains('cell-settings-attached')) {
      return;
    }
    element.innerHTML = /* html */ `
          <lyst-fa-icon type="solid" icon="gear" custom-color="#FFF"></lyst-fa-icon>
        `;
    element.addEventListener('click', clickColumnSettingsInParent);
    element.classList.add('cell-settings-attached');
  });
};

window.changeAllCellMore = changeAllCellMore;

const changeColumnToolButtons = () => {

  const interval = setInterval(() => {
    const targetNode = document.body;
    if (!targetNode || !document.querySelectorAll('.cell-more').length) {
      return;
    }
    clearInterval(interval);

    changeAllCellMore();

    /* ----- attach mutation observer start ----- */
    // Callback function to execute when mutations are observed
    // const callback = () => {
    //   changeAllCellMore();
    // };

    // // Create an observer instance linked to the callback function
    // const observer = new MutationObserver(callback);

    // // Start observing the target node for configured mutations
    // observer.observe(targetNode, { subtree: true, childList: true });

    /* ----- attach mutation observer end ----- */
  }, 1000);
};


export default changeColumnToolButtons;
