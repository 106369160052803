import changeBoxToolButtons from './changeBoxToolButtons';
import changeColumnToolButtons from './changeColumnToolButtons';
import changeElmToolButtons from './changeElementToolButtons';
import changeRowToolButtons from './changeRowToolButtons';
import changeSectionToolButtons from './changeSectionToolButtons';

changeColumnToolButtons();
changeRowToolButtons();
changeBoxToolButtons();
changeSectionToolButtons();
changeElmToolButtons();

window.applyEditorChanges = () => {
  window.changeAllBoxEdit?.();
  window.changeAllCellMore?.();
  window.changeElmOptions?.();
  window.changeAllRowMore?.();
  window.changeAllSectionEdit?.();
};

document.addEventListener('selectionchange', (event) => {
  const selection = document.getSelection();
  const btnLink = parent.document.querySelector('.panel-text .btn-link') as
    | HTMLButtonElement
    | HTMLAnchorElement
    | null;
  if (selection?.focusOffset === selection?.anchorOffset) {
    if (btnLink) {
      btnLink.style.display = 'none';
    }
  } else {
    if (btnLink) {
      btnLink.style.display = 'initial';
    }
  }
});

if (!customElements.get('lyst-widget-wrapper')) {
  import('../lystCustomElements/lystWidgetWrapper');
}
